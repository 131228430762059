import Navbar from "./Navbar";
import { useEffect } from "react"
export default function UnauthorizedPage(props) {


    useEffect(() => {
        window.history.replaceState({ page: "display_page" }, "display_page", '/');
    }, [])

    return (<div>
        <Navbar />
        <div Style={' height: calc(100vh - 70px);align-content: center;'}>
            <div className='label_text_bold center ' Style={'font-size:22px'}>Not authorized</div>
        </div>
    </div>)
}
import { useEffect, useState } from "react";
import ExportButtons from "./ExportButtons";
import Filters from "./Filters";
import Navbar from "./Navbar";
import TableComponent from "./TableComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide } from 'react-toastify';
import { useOktaAuth } from "@okta/okta-react";
import { oktaAuth } from "../config";
import Service from "../services/Service";
import UnauthorizedPage from "./UnauthorizedPage";
import ErrorPage from "./ErrorPage";
import { useNavigate } from 'react-router-dom'
export default function Homepage(props) {
    const [location, setLocation] = useState(null)
    const [locationDescription, setLocationDescription] = useState(null)
    const [brands, setBrands] = useState([])
    const [selectedBrands, setSelectedBrands] = useState([])
    const [locations, setLocations] = useState(null)
    let today = new Date()
    today.setMonth(today.getMonth() - 1)
    const [date, setDate] = useState(today);
    const [loadingExp, setLoadingExp] = useState(false);
    const { authState } = useOktaAuth();
    const [user, setUser] = useState(null)
    const loggingIn = async () => oktaAuth.signInWithRedirect({ originalUri: "/" });
    const service = new Service()
    const navigate = useNavigate()

    useEffect(() => {

        service.getUser().then((data) => {
            setUser(data)
            if (data && data.default_store && data.default_store.location_code && data.default_store.location_description)
                setLocation({ label: data.default_store.location_code + ' ' + data.default_store.location_description, value: data.default_store.location_code })

            service.getAllLocations().then((data) => {
                let loc = []
                data.map((d) => {

                    loc.push({
                        label: d.location_code + ' ' + d.location_description,
                        value: d.location_code
                    })
                })
                setLocations([...loc])
            }).catch((err) => {
                toast.error(err.message, {
                    background: "#00FF00 !important",
                    transition: Slide,
                    toastId: 'export_error1'
                })
            })
        }).catch((error) => {
            if (error.response.status == 401) {
                if (error.response.data.code === "unauthorized") {
                    navigate('unauthorized')
                }
                else if (error.response.data.code === "expired_token") {
                    localStorage.setItem('refresh', true)
                    window.location.reload()
                }
                //  else
                //   loggingIn()
            }
        })

    }, [])

    useEffect(() => {
        if (location && location.value)
            getBrands(location.value)
    }, [location])

    const getBrands = (loc) => {
        service.getBrandsByLocation(loc).then((data) => {
            let br = []
            data.map((b) =>
                br.push({
                    label: b.brand,
                    value: b.id
                })
            )
            setBrands(br)
        })
    }
    const exportSales = () => {
        setLoadingExp(true)
        if (date) {
            let obj = {
                "year": date.getFullYear(),
                "month": date.getMonth() + 1
            }
            service.exportData(obj, location.value).then(() => {
                setLoadingExp(false)
            }).catch((err) => {
                setLoadingExp(false)
                toast.error(err.message, {
                    background: "#00FF00 !important",
                    transition: Slide,
                    toastId: 'export_error1'
                })
            })
        }
        else
            toast.error('Please select a valid date!')

    }
    return (
        locations && locations.length > 0 ?
            <div>
                <Navbar />
                <div className="flex subheader">
                    <div Style={'width:70%'}>
                        <Filters selectedBrands={selectedBrands} setSelectedBrands={setSelectedBrands} locations={locations} locationDescription={locationDescription} location={location} setLocation={setLocation} brands={brands} setBrands={setBrands} setDate={setDate} date={date} setLoading={setLoadingExp} />
                    </div>
                    <div Style={'width:30%;    text-align: -webkit-right;align-content: center; z-index:1'}>
                        <ExportButtons date={date} exportSales={exportSales} user={user} locations={locations} locationDescription={locationDescription} location={location} setLoading={setLoadingExp} brands={brands} />
                    </div>
                </div>
                {!loadingExp ?
                    <div className="table-container">
                        <TableComponent setLocationDescription={setLocationDescription} setLocation={setLocation} location={location} brands={brands} selectedBrands={selectedBrands} date={date} />
                    </div>
                    : <div className="loader-cont">
                        <div className="loader1"></div>
                    </div>}
                <ToastContainer stacked
                />
            </div> :
            ''
    )
}
import './App.scss';
import Homepage from './views/Homepage';
import MyRoutes from './MyRoutes';
import { BrowserRouter } from "react-router-dom";
function App() {
  return (<div className="App">
    <MyRoutes />
  </div>);
}

export default App;
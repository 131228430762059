import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginCallback, useOktaAuth } from '@okta/okta-react'
import { oktaAuth } from '../config'


export const RequiredAuth = ({children}) => {
    const { authState } = useOktaAuth()
    const navigate = useNavigate()

    useEffect(() => {
        const navigateToUnautorizedPage = () => navigate('unauthorized')
        window.addEventListener('unautorized', navigateToUnautorizedPage)
        return () => {
            window.removeEventListener('unautorized', navigateToUnautorizedPage)
        }
    }, [navigate])

    useEffect(() => {
        if (!authState) {
            return
        }

        if (!authState?.isAuthenticated) {
            oktaAuth.signInWithRedirect({ originalUri: '/' })
        }

    }, [authState?.isAuthenticated, authState])

    if (!authState || !authState?.isAuthenticated) {
        return <LoginCallback />
    }

    return (
        children
    )
}

import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { oktaAuth } from '../config'

export const AppProvider = () => {
    const navigate = useNavigate()
    const restoreOriginalUri = async (oktaAuth, originalUri) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin))
    }

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Outlet />
        </Security>
    )
}

import { Route, Routes, useNavigate } from "react-router-dom";
import React, { lazy, Suspense } from 'react'
import { LoginCallback, Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { oktaAuth } from "./config";
import Homepage from "./views/Homepage";
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import { AppProvider } from "./routing/AppProvider";
import { RequiredAuth } from "./routing/RequiredAuth";
import { RootRoutes } from "./routing/RootRoutes";
import UnauthorizedPage from "./views/UnauthorizedPage";
import PageNotFound from "./views/PageNotFound";
import { ErrorComponent } from "./views/ErrorComponent";
const MyRoutes = () => {
	let app_basename = ""
	if (window.location.hostname === 'hrintegration.globalbit.gucci')
		app_basename = "/competitorstool/"
	return (
		<RouterProvider
			router={
				createBrowserRouter(
					[
						{
							element: <AppProvider />,
							children: [
								{
									path: '/login/callback',
									element: <LoginCallback errorComponent={ErrorComponent} />,
								},
								{
									path: '/',
									element: (
										<RequiredAuth children={<Suspense fallback={null}>
											<Outlet />
										</Suspense>}>
										</RequiredAuth>
									),
									children: [...RootRoutes],
								},
								{
									path: '/unauthorized',
									element: (
										<RequiredAuth>
											<UnauthorizedPage />
										</RequiredAuth>
									),
								},
								{
									path: '_blank',
									element: null,
								},
								{
									path: '*',
									element: (
										<RequiredAuth>
											<PageNotFound />
										</RequiredAuth>
									),
								},
							],
						},
					],
					{ basename: app_basename })
			} />
	);
};
export default MyRoutes;
import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import Service from '../services/Service';
import Select from 'react-select';
import { multiselectStyle } from '../global';
export default function Filters(props) {
    const [filters, setFilters] = useState({})
    const locRef = React.createRef();
    const brandRef = React.createRef();
    const [locations, setLocations] = useState(props.locations ? props.locations : [])
    const [brands, setBrands] = useState([])
    const [selectedLocation, setSelectedLocation] = useState(null)
    var typingTimer;                //timer identifier
    var doneTypingInterval = 1000;
    let service = new Service()

    const selectStyle = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected || state.isFocused ? '#fff!important' : 'black',
            backgroundColor: state.isSelected || state.isFocused || state.isDisabled ? '#4b000a' : 'white',
            padding: '6px 2px !important',
            cursor: 'pointer'
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: '100%',
            display: 'flex',
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }
    const setField = (e, name) => {
        let f = filters
        f[name] = e
        setFilters({ ...f })
        if (name === 'brand')
            props.setSelectedBrands(e)
    }

    useEffect(() => {
        if (props.brands)
            setBrands(props.brands)
    }, [props.brands])
    useEffect(() => {

        setLocations(props.locations)
    }, [props.locations])

    useEffect(() => {
        setSelectedLocation(props.location)
    }, [props.location])
    /*  useEffect(() => {


          addListener()

          return () => {
              removeListener()
          }

      }, [])*/

    /* const addListener = () => {
         window.addEventListener("keyup", addManual, false);
         window.addEventListener("keydown", clear, false);
     }
     const removeListener = () => {
         window.removeEventListener("keyup", addManual, false);
         window.removeEventListener("keydown", clear, false);
     }
     function addManual() {
         clearTimeout(typingTimer);
         typingTimer = setTimeout(doneTypingManual, doneTypingInterval);
     }
     function clear() {
         clearTimeout(typingTimer);
     }
     const doneTypingManual = () => {
         setSelectedLocation(null)
         let element = document.querySelector('#input-select >div >div>div>input')
         if (element && element.value) {
             let loc = []
             service.searchLocation(element.value).then((data) => {
                 data.map((d) => {

                     loc.push({
                         label: d.location_code + ' ' + d.location_description,
                         value: d.location_code
                     })
                 })
                 setLocations(loc)
             })
         }

     }*/

    const handleChange = (e) => {
        props.setLocation(e)
        setField(null, 'brand')
    }
    return (
        <div className='filters_container'>
            <div className='filters'>
                <div className='filter' Style={'padding-left:0'}>
                    <label className='bold filter-label'>Gucci Locations</label>
                    <Select
                        placeholder={selectedLocation && selectedLocation.label ? selectedLocation.label : 'Select'}
                        onChange={handleChange}
                        options={locations}
                        styles={selectStyle}
                        id='input-select'
                    />
                </div>
                {/*      <div className='filter'>
                    <label className='bold filter-label'> Brand</label>
                    <Multiselect
                        options={brands} // Options to display in the dropdown
                        selectedValues={filters['brand'] ? filters['brand'] : null} // Preselected value to persist in dropdown
                        onSelect={(e) => setField(e, 'brand')} // Function will trigger on select event
                        onRemove={(e) => setField(e, 'brand')} // Function will trigger on remove event
                        displayValue="label" // Property name to display in the dropdown options
                        showCheckbox={true}
                        avoidHighlightFirstOption={true}
                        style={multiselectStyle}
                        ref={brandRef}
                        placeholder=''
                    />
                </div>*/}
                <div className='filter'>
                    <label className='bold filter-label flex'>Month <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar3 ml-3 mt-2" viewBox="0 0 16 16">
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                    </svg>
                        {/*   <svg onClick={() => props.setDate(null)} xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" class="bi bi-x-lg ml-10 pointer" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>*/}
                    </label>
                    <DatePicker
                        selected={props.date}
                        onChange={(date) => props.setDate(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                    />
                </div>
            </div>
        </div >)
}
import Navbar from "./Navbar";

export default function PageNotFound(props) {

    return (<div>
        <Navbar />
        <div Style={' height: calc(100vh - 70px);align-content: center;'}>
            <div className='label_text_bold center ' Style={'font-size:22px'}>Page not Found</div>
        </div>
    </div>)
}